import React from 'react';
import PropTypes from 'prop-types';
import ClosestDelivery from 'components/ClosestDelivery';
import Account from 'components/Account';
import ToCatalog from 'components/ToCatalog';
import { useGlobal } from './hooks';
import './FirstHeader.module.css';

const getViewer = s => s.viewer;

const FirstHeader = ({ phoneVisible, onProduct }) => {
  const [viewer] = useGlobal(getViewer);

  const accountView = (
    <Account to="my">
      <span styleName="authenticated-text">
        {viewer ? (
          <React.Fragment>
            Заказы{' '}
            {viewer.totalOrders > 0 && (
              <div styleName="authenticated-orders">
                <span styleName="authenticated-orders__qty">{viewer.totalOrders}</span>
              </div>
            )}
          </React.Fragment>
        ) : (
          'Авторизуйтесь, чтобы сохранять историю заказов'
        )}
      </span>
    </Account>
  );
  return (
    <div styleName="root">
      {!onProduct ? accountView : <ToCatalog size={30} />}
      <a // eslint-disable-line jsx-a11y/anchor-has-content
        href="https://itunes.apple.com/ru/app/moscowfresh/id1114442063"
        target="_blank"
        rel="noopener noreferrer"
        title="Заказывайте через приложение для iPhone!"
        styleName="appstore"
      />
      <ClosestDelivery phoneVisible={phoneVisible} />
    </div>
  );
};

FirstHeader.propTypes = {
  phoneVisible: PropTypes.bool,
  onProduct: PropTypes.bool,
};

FirstHeader.defaultProps = {
  phoneVisible: true,
  onProduct: false,
};

export default FirstHeader;
