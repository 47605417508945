import React from 'react';
import { Link } from 'gatsby';
import Phone from 'components/Phone';
import './Footer.module.css';

const Footer = () => (
  <div styleName="footer">
    <div styleName="box">
      <div>{`© 2015-${new Date().getFullYear()} MoscowFresh`}</div>
      <div styleName="links">
        <div styleName="texts">
          <Link to="/about-us" styleName="link">
            О сервисе
          </Link>
          <span styleName="seporator" />
          <Link to="/deposit" styleName="link">
            Депозиты
          </Link>
          <span styleName="seporator" />
          <Link to="/uslovija-dostavki" styleName="link">
            Условия доставки
          </Link>
          <span styleName="seporator" />
          <div styleName="contacts">
            <span>Вопросы? </span>
            <a href="mailto:client@moscowfresh.ru" styleName="black-link">
              client@moscowfresh.ru
            </a>{' '}
            <Phone styleName="black-link" />
          </div>
        </div>
      </div>
      <Link styleName="link user-agreement" to="/privacy-policy-cookie-restriction-mode" target="_blank">
        Пользовательское соглашение
      </Link>
    </div>
  </div>
);

export default Footer;
