import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'throttle-debounce';
import { Link } from 'gatsby';
import { useGlobal } from 'components/hooks';
import Search from 'components/Search';
import scrollToCategory from 'utils/scrollToCategory';
import MiniCart from './MiniCart';
import SearchIcon from './icons/Search';
import Account from './Account';

import './SecondHeader.module.css';

const withDetox = category => category.products.some(p => p.detox);

const toCategory = id => e => {
  e.preventDefault();
  scrollToCategory(id);
};

const getViewer = s => s.viewer;

const SecondHeader = ({ categories, onlyDetox }) => {
  const [viewer] = useGlobal(getViewer);
  const [isSearch, setSearch] = useState(false);

  const openSearch = () => setSearch(true);

  const closeSearch = () => setSearch(false);

  const newCats = onlyDetox ? categories.filter(withDetox) : categories;

  const markerEl = useRef(null);
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(100, () => {
      if (markerEl.current) {
        setFixed(window.pageYOffset + 10 > markerEl.current.offsetTop);
      }
    });

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [markerEl]);

  return (
    <div ref={markerEl} style={{ height: 82 }}>
      <div styleName={fixed ? 'fixed' : 'relative'}>
        <div styleName={fixed ? 'background-fixed' : 'background-relative'} />
        <div styleName="account">
          <Account to={viewer ? 'my' : 'login'} styleName="account-button" />
        </div>
        {isSearch ? (
          <Search onClose={closeSearch} iconColor={fixed ? 'black' : 'white'} />
        ) : (
          <div styleName="main category">
            <div styleName="links">
              {newCats.map(c => (
                <a
                  styleName="item"
                  key={c.entity_id}
                  href={`/#category-${c.entity_id}`}
                  onClick={toCategory(c.entity_id)}
                >
                  {c.name}
                </a>
              ))}
              <Link to={onlyDetox ? '/' : '/vegan'} styleName={onlyDetox ? 'item-normie' : 'item-vegan'}>
                {onlyDetox ? 'Вернуть все продукты' : 'vegan'}
              </Link>
            </div>
            <button onClick={openSearch} type="button" styleName="search">
              <SearchIcon color={fixed ? 'black' : 'white'} size={28} styleName="search-icon" />
            </button>
          </div>
        )}
        <div styleName="cart">
          <MiniCart styleName="mini-cart" />
        </div>
      </div>
    </div>
  );
};

SecondHeader.propTypes = {
  categories: PropTypes.array.isRequired,
  onlyDetox: PropTypes.bool,
};

SecondHeader.defaultProps = {
  onlyDetox: false,
};

export default SecondHeader;
