/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './BenefitRow.module.css';

const Popup = ({ text }) => (
  <div styleName="popup">
    <div styleName="popup-cross" />
    <div styleName="popup-text">{text}</div>
  </div>
);

Popup.propTypes = {
  text: PropTypes.string.isRequired,
};

const benefits = [
  {
    title: 'fresh',
    text: 'Изобилие невероятно вкусных свежих продуктов',
    popupText:
      'Самолетные фрукты, фермерские молочные продукты, охлажденная и дикая рыба, редкие сыры и колбасы, органические продукты - всё это и многое другое, свежее и вкусное, ждет вас на MoscowFresh. И за каждый товар мы смело ручаемся, так как он прошел многоступенчатую систему отбора и критику наших клиентов.',
    icon: 'basket',
    id: 1,
  },
  {
    title: 'quality',
    text: 'Отбираем лучшие продукты и гарантируем качество',
    popupText:
      'Некачественным продуктам будет очень трудно прорваться через наш пятиступенчатый контроль качества, но если несмотря ни на что вам все же что-то не понравится, просто пришлите нам после заказа фотографию этого продукта, и мы вернем за него деньги или предложим через 2 часа произвести замену.',
    icon: 'tomato',
    id: 2,
  },
  {
    title: 'delivery',
    text: 'Бережно упаковываем и быстро доставляем за 290р или бесплатно',
    popupText:
      'Мы базируемся в центре Москвы, поэтому 99% заказов доставляем точно в назначенный часовой интервал. От 5000 руб доставка бесплатная. Охлажденные продукты кладутся в термопакеты, ягоды и нежные фрукты - в прочные контейнеры, а остальное - в биоразлагаемые и крафт-пакеты.',
    icon: 'courier',
    id: 3,
  },
];

const BenefitRow = () => {
  const [opened, setOpened] = useState(null);
  const handleOpened = tab => {
    if (tab === opened) {
      setOpened(null);
      return;
    }
    setOpened(tab);
  };
  return (
    <div styleName="wrapper">
      <div styleName="box">
        {benefits.map(benefit => (
          <div styleName="item" key={benefit.id} onClick={() => handleOpened(benefit.title)} role="presentation">
            {opened === benefit.title && <Popup text={benefit.popupText} />}
            <div styleName={`icon-${benefit.icon}`} />
            <div styleName="item-text">{benefit.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitRow;
