/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useInitialScroll } from 'utils/scrollToCategory';
import withSizes from 'react-sizes';
import Analytics from 'components/Analytics';

import FirstHeader from 'components/FirstHeader';
import Hello from 'components/Hello';
import Reviews from 'components/Reviews';
import Recommendations from 'components/Recommendations';
import BenefitRow from 'components/BenefitRow';
import OnSale from 'components/OnSale';
import SecondHeader from 'components/SecondHeader';
import Footer from 'components/Footer';
import ProductPlane from 'components/ProductPlane';

import './index.module.css';

const REVIEWS_PADDING = 94;
const REVIEW_WIDTH = 320;
const SSR_SCREEN_WIDTH = 3840; // 4k

const IndexPage = ({ data, size, onlyDetox }) => {
  const { onsale, recommendations, categories } = data.magento.cachedCatalog;
  const width = size.width || SSR_SCREEN_WIDTH;
  const perRow = Math.floor(width / 320);

  // это вызывало ошибку при window.onresize
  // useEffect(() => {
  //   if (typeof window !== 'undefined' && width <= 666) {
  //     if (onlyDetox) {
  //       navigate(`/vegan-mob/${location.search}${location.hash}`);
  //     } else {
  //       navigate(`/mob/${location.search}${location.hash}`);
  //     }
  //   }
  // }, [width, onlyDetox]);

  useInitialScroll();

  return (
    <React.Fragment>
      <Helmet>
        <title>Moscowfresh — экспресс-доставка свежих и натуральных продуктов</title>
        <meta
          name="description"
          content="Большой выбор фруктов и овощей, свежей рыбы, фермерского мяса, молочных продуктов, орехов и сладостей. Доставка за 2 часа."
        />
      </Helmet>
      <Analytics type="viewHome" />
      <FirstHeader />
      <Hello />
      {recommendations.length > 0 && (
        <Recommendations onlyDetox={onlyDetox} products={recommendations[0].products} perRow={perRow} />
      )}
      <SecondHeader categories={categories} onlyDetox={onlyDetox} />
      <BenefitRow />
      <Reviews reviews={data.magento.reviews} perRow={Math.floor((width - REVIEWS_PADDING) / REVIEW_WIDTH)} />
      {onsale.length > 0 && <OnSale onlyDetox={onlyDetox} products={onsale} perRow={perRow} />}
      <ProductPlane categories={categories} perRow={perRow} onlyDetox={onlyDetox} />
      <Footer />
    </React.Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onlyDetox: PropTypes.bool,
};

IndexPage.defaultProps = {
  onlyDetox: false,
};

export const query = graphql`
  fragment HomeData on Query {
    magento {
      ...Reviews

      cachedCatalog {
        recommendations {
          name
          products {
            ...ProductCard
          }
        }

        onsale {
          ...ProductCard
        }

        categories {
          entity_id
          name
          products(all: true) {
            ...ProductCard
          }
        }
      }
    }
  }

  query HomeQuery {
    ...HomeData
  }
`;

export default withSizes(size => ({ size }))(IndexPage);
