import React from 'react';
import PropTypes from 'prop-types';
import MiniCart from 'components/MiniCart';
import Phone from 'components/Phone';
import './ClosestDelivery.module.css';

const ClosestDelivery = ({ phoneVisible }) => (
  <div styleName="root">
    {phoneVisible && false && (
      <React.Fragment>
        <Phone styleName="phone" />
        <div styleName="icon-clock" />
        <div styleName="text">
          Доставим за&nbsp;<span styleName="interval">1 час</span>
        </div>
      </React.Fragment>
    )}
    <MiniCart />
  </div>
);

ClosestDelivery.propTypes = {
  phoneVisible: PropTypes.bool.isRequired,
};

export default ClosestDelivery;
